import  React from 'react';

import './Partners.css';
import Footer from './Footer';
import './Global.css';

const partners = [
  { id: 'urban-radar', idText: 'urban-text', business: 'Urban Radar', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/urban-radar.png', link: 'https://urbanradar.io/', text: 'An independent third-party visualization and data analytics company.'},
  { id: 'solutions', idText: 'solutions-text', business: 'TH Solutions', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/thsolutions.png', link: 'https://www.telegraphhillsolutions.com/', text: 'A government relations, political research and campaign strategy firm.'},
  { id: 'depaul', idText: 'depaul-text', business: 'Depaul University', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/depaul.png', link: 'https://las.depaul.edu/centers-and-institutes/chaddick-institute-for-metropolitan-development/Pages/default.aspx', text: 'A private university in Chicago, Illinois founded in 1898'}
]

const Partners = () => {
  return (
    <div className="container-fluid">
    <div className="row">
      <div className="col-md-12  col-xs-12">
        <h2 id="partners-margin" className="heading-margin">PARTNERS</h2>
      </div>
        {partners.map((el, index) => {
          return (
            <div className="col-md-4 col-sm-12 col-xs-12 wrapper">
              <a href={el.link}><img alt="logo" id={el.id} className="partners-image image-fluid" src={el.image} /></a>
              <p id={el.idText} className="partners-text text-center">{el.text}</p>
            </div>
          );
        })}
        <div style={{paddingTop: '100px', width: '100%'}}>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Partners;
