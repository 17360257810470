import React from 'react';
import './Global.css';
import './OurWork.css';
import Footer from './Footer';

import Aviator from '../assets/pdf/IALP - Bordenkircher22 article Volume 22 Number 1 (Winter 2022).pdf'
import Covid from '../assets/pdf/Covid 19, Policy Enactment and Political Ideology.pdf'
import Pot from '../assets/pdf/Equity, Cannabis, and the State of Illinois.pdf';
import Dockless from '../assets/pdf/Dockless Bike - Regulation Breakdown (12 Tone Consulting).pdf'
import Future from '../assets/pdf/ChaddickUrbanTechnologySeries_RaceToTheFuture.pdf';
import Blue from '../assets/pdf/IMPJ - MANAGING THE SHARING ECONOMY MUNICIPAL POLICY RESPONSES TO HOMESHARING, RIDESHARING AND BIKESHARING IN ILLINOIS.pdf';
import Urban from '../assets/pdf/Urban Technology Series - Delivery Robots.pdf';
import Electric from '../assets/pdf/Electric Scooters - Regulation Breakdown (12 Tone Consulting).pdf';
import Aviation from '../assets/pdf/ISSUES IN AVIATION LAW & POLICY JOUNRAL - Bordenkircher article Volume 19 Number 2 (Spring 2020).pdf';
import Autonomous from '../assets/pdf/Autonomous Vehicle Policy Adoption.pdf';
import Trb from '../assets/pdf/TRB.pdf';
import Green from '../assets/pdf/Going Green-IMPJ-2022-Volume-7-Issue-2-PDF.pdf';

const OurWork = () => {

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h2 id="our-work-margin" className="heading-margin">OUR WORK</h2>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Green}><img id="dockless" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/municipal.png" alt="dockless bikes pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Aviator}><img className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/aviation.jpg" alt="un-innovation book"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/INNOVATION-Divided-America-Future-Technology/dp/B09HJ17X9B/ref=sr_1_1?dchild=1&keywords=uninnovation&qid=1634335138&s=books&sr=1-1
            "><img className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/un-innovation.png" alt="un-innovation book"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Covid}><img className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/covid-19.png" alt="autonomous pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Autonomous}><img className="img-fluid pdf-img" src="http://s3.us-east-2.amazonaws.com/12toneconsulting.com/autonomous.png" alt="autonomous pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Aviation}><img className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/aviation.png" alt="race to future pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Future}><img id="race" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/race-to-future.jpg" alt="race to future pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Trb}><img id="trb" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/trbb.png" alt="trb pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Pot}><img id="pot" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/pot.png" alt="marijuana pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Urban}><img id="urban" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/delivery.png" alt="urban tech series pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Blue}><img id="illinois" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/illinois.png" alt="municipal policy pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Electric}><img id="electric" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/scooters.png" alt="electric scooters pdf"/></a>
          </div>
          <div className="col-md-3 offset-md-1 col-sm-6 col-xs-12">
            <a target="_blank" rel="noopener noreferrer" href={Dockless}><img id="dockless" className="img-fluid pdf-img" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/dockless.png" alt="dockless bikes pdf"/></a>
          </div>
          <div style={{paddingTop: '50px', width: '100%'}}>
            <Footer />
          </div>
          </div>
        </div>
    );
  }


export default OurWork;
