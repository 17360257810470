import React from 'react';
import './Global.css';
import './WhoWeAre.css';
import Footer from './Footer';


const WhoWeAre = () => {

    const people = [
      { id: 'brandon', name: 'Brandon Bordenkircher, MPA', role: 'C.E.O. & Founder', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/brandon.jpeg', linkedin: 'https://www.linkedin.com/in/babordenkircher/' },
      { id: 'kiristin', name: 'Kirstin Anderson-Hall', role: 'Principal Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/kirstin.jpeg', linkedin: 'https://www.linkedin.com/in/khall12891/'},
      { id: 'Brady', name: 'Brady Penn', role: 'Advisor, C.E.O. Telegraph Hill Solutions', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/brady.jpeg', linkedin: 'https://www.linkedin.com/in/pennbrady/'},
      { id: 'Riley', name: 'Riley O’Neil', role: 'Director of Transportation', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/riley.jpeg', linkedin: 'https://www.linkedin.com/in/riley-o-neil-90b46995/'},
      { id: 'Jared', name: 'Jared Lewis', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/jared.jpeg', linkedin: 'https://www.linkedin.com/in/jaredmlewis'},
      { id: 'alexander', name: 'Alexander Hall', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/alexander.jpeg', linkedin: 'https://www.linkedin.com/in/alexanderhall86/'},
      { id: 'Ali', name: 'Ali Fendrick', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/ali.jpeg', linkedin: 'https://www.linkedin.com/in/ali-fendrick-21382443/'},
      { id: 'johanna', name: 'Johanna Jamison, M.P.A.', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/johanna.jpeg', linkedin: 'https://www.linkedin.com/in/johanna-lee-jamison-mpa/'},
      { id: 'arlinda', name: 'Arlinda Bajrami', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/arlinda.jpeg', linkedin: 'https://www.linkedin.com/in/arlinda-bajrami-1990/'},
      { id: 'gen', name: 'Genevieve Nemeth, PE', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/genevieve.jpeg', linkedin: 'https://www.linkedin.com/in/genevievenemeth/'},
      { id: 'egan', first: 'dan', number: 'nine', name: 'Daniel Egan', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/daniel.jpeg', linkedin: 'https://www.linkedin.com/in/daniel-egan-r/'},
      { id: 'mallory', name: 'Mallory Livingston Shurna', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/mallory.jpeg', linkedin: 'https://www.linkedin.com/in/mallory-livingston-shurna-b1365385/'},
      { id: 'grace', name: 'Grace (Gouker) Littlefield', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/grace.jpeg', linkedin: 'https://www.linkedin.com/in/gracelittlefield/'},
      { id: 'jessica', name: 'Jessica Kupets', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/jessica.jpeg', linkedin: 'https://www.linkedin.com/in/jessicakupets/'},
      { id: 'carina', name: 'Carina Fortuna', role: 'Consultant', image: 'https://s3.us-east-2.amazonaws.com/12toneconsulting.com/carina.jpeg', linkedin: 'https://www.linkedin.com/in/carina-fortuna-a933b238/'},
      { id: 'kenny', name: 'Kenny Montilla', role: 'Consultant', image: '  https://s3.us-east-2.amazonaws.com/12toneconsulting.com/kenny.jpeg', linkedin: 'https://www.linkedin.com/in/kmontilla/'}


    ]

    return (
      <div>
        <h2 id="who" className="heading-margin">WHO WE ARE</h2>
        <div className="row">
          {people.map((el, index) => {
            return (
              <div id="index" key={el.name} className="col-md-3 col-lg-12">
                <img className="staff-image" alt="staff" src={el.image}/>
                <h4 id={el.id} className="name text-center">{el.name}</h4>
                <h6 id={el.id} className="role text-center">{el.role}</h6>
                <div className="col-md-12 flex-center">
                  <a id={el.id} target="_blank" rel="noopener noreferrer" className="in" href={el.linkedin}>(in)</a>
                </div>
              </div>
            );
          })}
        </div>
        <Footer />
      </div>
    );
  }




export default WhoWeAre;
