import React from 'react';
import './Press.css';
import Footer from './Footer';


const Press = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 col-xs-12">
          <h2  id="press-margin" className="heading-margin">PRESS</h2>
        </div>
        <div className="col-md-12">
          <img  alt="newpspaper" id="press-image" className="image-fluid" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/Press+(thinner).png" />

          <a rel="noopener noreferrer" target="_blank" href="https://scholar.google.com/citations?user=rnKfG1IAAAAJ&hl=en"><p className="press-styles">Google Scholar Citations (Brandon Bordenkircher)</p></a>

          <a rel="noopener noreferrer" target="_blank" href="https://www.stitcher.com/podcast/govlove/e/60295964"><p className="press-styles">GovLove Podcast: American Planning Association Conference (4/26/2019)</p></a>

          <a rel="noopener noreferrer" target="_blank" href="http://sump-network.eu/fileadmin/user_upload/downloads/innovation_briefs/PROSPERITY_Innovation_Brief_Regulating_dockless_bike-sharing_schemes_140918_web_EN.pdf">
          <p className="press-styles">CIVITAS: Innovation Brief, Regulating dockless bikesharing schemes (Date unknown)</p></a>

          <a rel="noopener noreferrer" target="_blank" href="https://denverite.com/2018/07/16/electric-scooter-denver-disrupt-first/">
          <p className="press-styles">Denverite: Electric scooter companies’ “disrupt first” Denver strategy seems to have worked (7/16/2018)</p></a>

          <a rel="noopener noreferrer" target="_blank" href="https://denverite.com/2018/08/27/denver-has-now-survived-a-month-of-scooters-and-there-are-a-lot-more-on-the-way/">
          <p className="press-styles">Denverite: Denver has now survived a month of scooters, and there are a lot more on the way (8/27/2018)</p></a>

          <a rel="noopener noreferrer" target="_blank" href="https://chi.streetsblog.org/2018/04/30/dockless-bike-share-policy-experts-discuss-the-pros-and-cons-of-chicagos-rules/">
          <p className="press-styles">StreetsBlog: Dockless Bike-Share Policy Experts Discuss the Pros and Cons of Chicago’s Rules (4/30/2018)</p></a>

          <a rel="noopener noreferrer" target="_blank" href="https://chi.streetsblog.org/2018/04/03/city-officials-take-note-dockless-bike-share-study-by-chicagoans-recommends-regulations">
          <p id="streets-blog" className="press-styles">StreetsBlog: Dockless Bike-Share Study by Chicago Consultants Recommends Best Practices (4/3/2018)</p></a>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Press;
