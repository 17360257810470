import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Partners from './Partners';
import Header from './Header';
import Home from './Home';
import OurStory from './OurStory';
import OurWork from './OurWork';
import WhoWeAre from './WhoWeAre';
import Blog from './Blog';
import Press from './Press';
import SideDrawer from './SideDrawer';
import Backdrop from './Backdrop';
import './App.css'

class App extends React.Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) =>  {
      return { sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  }

  render() {
    let sideDrawer;
    let backdrop;

    if(this.state.sideDrawerOpen){
      sideDrawer = <SideDrawer click={this.drawerToggleClickHandler}/>
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }

    return(
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-xs-12 col-lg-12">
            <BrowserRouter>
              <Header drawerClickHandler={this.drawerToggleClickHandler}/>
              { sideDrawer }
              { backdrop }
              <div>
                <Switch>
                  <Route  path="/" exact component={Home}/>
                  <Route  path="/our-story" exact component={OurStory} />
                  <Route  path="/our-work" exact component={OurWork}/>
                  <Route  path="/who-we-are" exact component={WhoWeAre}/>
                  <Route  path="/press" exact component={Press}/>
                  <Route  path="/partners" exact component={Partners}/>
                  <Route  path="/blog" exact component={Blog}/>
                  <Route  component={Home} />
                </Switch>
              </div>
            </BrowserRouter>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
