import React from 'react';
import './HamburgerMenu.css';

const HamburgerMenu = (props) => {
  return (
    <div>
      <button className="toggle-button" onClick={props.click}>
        <div className="toggle-button-line" />
        <div className="toggle-button-line" />
        <div className="toggle-button-line" />
      </button>
    </div>
  );
}

export default HamburgerMenu;
