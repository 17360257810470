import React from 'react';
import './Global.css';
import './Home.css';
import Footer from './Footer';

const Home = () => {
  return (
      <div>
        <div id="img-container">
          <img className="img-fluid" id="img-people" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/home-crowd.jpg" alt="people" />
          <p id="image-text" >Turning complex ideas<br></br> into clear, simple concepts</p>
        </div>
        <p className="paragraph-styles new-tech">New tech is being developed daily.</p>
        <p className="paragraph-styles new-tech">It is pertinent that companies take the initiative to educate elected officials looking to regulate these technologies in order to pass win/win legislation.</p>

        <p className="paragraph-styles new-tech">Our firm seeks to help move local municipalities into the future by helping them embrace and incorporate new disruptive technologies into their communities.</p>
        <div>
          <p className="heading-margin what-we-do">OUR SERVICES</p>
        </div>
        <div className="vertical-center">
          <ul>
            <li className="what-we-do-list-item">GOVERNMENT RELATIONS: Lobby elected officials and maneuver around regulatory hurdles.</li>
            <li className="what-we-do-list-item">ADVOCACY: Engage and activate stakeholders to achieve our client’s policy goals.</li>
            <li className="what-we-do-list-item">POLICY DEVELOPMENT: Draft policy papers and utilize applied research to make a compelling case for your business.</li>
            <li className="what-we-do-list-item">COMMUNICATIONS: Craft persuasive messaging via content creation and public relations.</li>
          </ul>
        </div>
        <p className="paragraph-styles contact" id="brandon-contact">Contact: brandon@twelvetoneconsulting.com</p>
        <Footer />
      </div>

  );
}

export default Home;
