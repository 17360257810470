import React from 'react';
import './BlogFooter.css';
import './Global.css';

const BlogFooter = () => {
  return (
    <div className="container-fluid">
      <h6 id="blog-footer" className="flex-center">©12 TONE CONSULTING</h6>
    </div>
  );
}

export default BlogFooter;
