import React from 'react';
import './Global.css';
import './OurStory.css';
import Footer from './Footer';

const OurStory = () => {
  return (
    <div className="row">
      <div className="col-md-12">
      <h2 id="our-story-margin" className="heading-margin">OUR STORY</h2>
      <p className="paragraph-styles">12 Tone Consulting was launched in 2018 by Brandon Bordenkircher, a global leader that has influenced tech policy in the United States, European Union, Norway, Singapore and Saudi Arabia.</p>

      <p className="paragraph-styles">Brandon has worked for various tech companies: as the General Manager of Share Now (Mercedes Benz car sharing app); the Deputy Program Director of Public Policy at Airbnb; Smart City Information Manager at IGNITE Cities; Government Affairs Manager at Tortoise; and Senior Advisor at the American AI Forum.</p>

      <p className="paragraph-styles">12 Tone Consulting's published works have been cited 30+ times by scholars all over the world and cover topics such as: A.I. in the Cockpit; the Sharing Economy; Smart Cities; Autonomous Delivery Robots; E-Scooters; and Dockless Bikes (Self Published).</p>

      <p id="margin-our-story" className="paragraph-styles">The firm operates at the intersection of the public, private, and nonprofit sectors with expertise in Government Affairs, Public Policy, Public Affairs and Business Development.</p>
      <div className="flex-center">
        <img alt="united states map" id="map-image" className="img-fluid" src="https://s3.us-east-2.amazonaws.com/12toneconsulting.com/map-new-florida.png" />
      </div>
      <Footer />
      </div>
    </div>

  );
}

export default OurStory;
