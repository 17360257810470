import React from 'react';
import axios from 'axios';
import './Global.css';
import './Blog.css'
import BlogFooter from './BlogFooter';

class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      error: null,
      items: []
    };
  }

  async componentWillMount() {
       await axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@TwelveTone")
      .then(response => {
        if (response.status === 200 && response != null) {
          this.setState({
            items: response.data.items,
            isLoaded: true
          });
          } else {
               console.log('There was an issue fetching the data');

             }
          })
          .catch(error => {
            this.setState({
              isLoaded: false,
              error
        });
      });
    }

    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
          } else if (!isLoaded) {
            return <h5 id="loading">Loading...</h5>;
          } else {
          return (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h2 id="blog-heading" className="heading-margin">BLOG</h2>
                </div>
                    {Array.isArray(items) && items.map((el, index) =>  {
                    return (
                      <div key={index} className="col-md-5 offset-md-1 col-sm-6 col-xs-12 col-lg-4">
                        <a target="_blank" rel="noopener noreferrer" href={el.link}>
                          <img id={el.title.slice(0, 10).toLowerCase()} key={index} alt={el.title} src={el.thumbnail} className="img-fluid blog-img"/>
                        </a>
                        <h5 className="blog-title">{el.title.replace('&amp;', '&')}</h5>
                      </div>
                    );
                  })}
                <BlogFooter />
              </div>
            </div>
          );
        }
      }
    }

   export default Blog;
