import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import HamburgerMenu from './HamburgerMenu';
import Logo from '../assets/images/12Tone.jpg';


const Header = (props)  => {
  return (
    <div className="flex-header">
      <div id="flex-title">
        <NavLink to="/"><img id="header-logo" src={Logo} alt="logo" /></NavLink>
      </div>
      <div id="flex-word">
        <h5 className="twelve">TWELVE</h5>
        <h5 className="twelve">TONE</h5>
        <h5 className="twelve">CONSULTING</h5>
      </div>
      <nav id="nav-parent">
        <div className="nav">
          <NavLink className="nav-li" activeClassName="nav-li-active" style={{textDecoration: 'inherit'}} exact to="/">HOME</NavLink>
          <NavLink className="nav-li" activeClassName="nav-li-active" style={{textDecoration: 'inherit'}}  exact to="/our-story">OUR STORY</NavLink>
          <NavLink className="nav-li" activeClassName="nav-li-active" style={{textDecoration: 'inherit'}} exact to="/our-work">OUR WORK</NavLink>
          <NavLink className="nav-li" activeClassName="nav-li-active" style={{textDecoration: 'inherit'}}   exact to="/who-we-are">WHO WE ARE</NavLink>
          <NavLink className="nav-li" activeClassName="nav-li-active" style={{textDecoration: 'inherit'}} exact to="/press">PRESS</NavLink>
          <NavLink className="nav-li" activeClassName="nav-li-active" style={{textDecoration: 'inherit'}} exact to="/partners">PARTNERS</NavLink>
          <NavLink id="blog" className="nav-li" activeClassName="nav-li-active" style={{textDecoration: 'inherit'}}  exact to="/blog">BLOG</NavLink>
        </div>
        <div>
          <HamburgerMenu click={props.drawerClickHandler}/>
        </div>
      </nav>
    </div>
  );
}

export default Header;
