import React from 'react';
import { Link } from 'react-router-dom';
import './SideDrawer.css';

const SideDrawer = (props) => {
  return (
    <div className="side-drawer">
      <ul className="side-drawer-ul">
        <li className="side-drawer-link"><Link to="/" onClick={props.click}>HOME</Link></li>
        <li className="side-drawer-link"><Link to="/our-story" onClick={props.click}>OUR STORY</Link></li>
        <li className="side-drawer-link"><Link to="/our-work" onClick={props.click}>OUR WORK</Link></li>
        <li className="side-drawer-link"><Link to="/who-we-are" onClick={props.click}>WHO WE ARE</Link></li>
        <li className="side-drawer-link"><Link to="/press" onClick={props.click}>PRESS</Link></li>
        <li className="side-drawer-link"><Link to="/partners" onClick={props.click}>PARTNERS</Link></li>
        <li className="side-drawer-link"><Link to="/blog" onClick={props.click}>BLOG</Link></li>
      </ul>
    </div>
  );
}

export default SideDrawer;
